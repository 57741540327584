html, body {
  font-family: 'Montserrat', sans-serif;
  color: $almost-black;
  background: $body-bg;
}

.pink {
  background: $pink;
}

p {
  font-size: 15px;
  line-height: 1.8em;
  font-weight: 500;
}

h2 {
  color: $dark-green !important;
  font-size: 1.5rem;
}

h3 {
  color: $dark-green !important;
  font-size: 1.3rem;
}

a {
  color: $dark-green;
  text-decoration: underline;
}

a:hover {
  color: $dark-highlight;
  transition: all 0.4s ease-in-out;
}

.active, a.select-type-on {
  color: $active !important;
  background: $body-bg !important;
}

.hero-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38vw;
  background-size: cover !important;
  background-position: center !important;
}


.hero-image-small {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18vw;
  background-size: cover !important;
  background-position: center !important;
}

.hero-text h1 {
  text-transform: uppercase;
  color: $body-bg;
  font-weight: 800;
  font-size: 1.1rem;
  letter-spacing: 0.1rem;
}


.page-header-image {
  height: 500px;
  background-size: cover !important;
}

.secondary_image {
  width: 250px;
  padding: 1rem 0;
}

.text-image-container {
  max-width: 80%;
}

.text-image {
  border: $beige 8px solid;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.25);
}

.left-col, .right-col {
  padding: 0 2rem;
}

.start {
  margin-top: 83px;
  transition: margin-top 0.3s ease;
}

.end {
  height: 40vh;
}

.end-2 {
  height: 20vh;
}

.page-header {
  background: $beige;
}

.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-block {
  padding: 4rem 0;
  width: 80%
}

.title-block h1 {
  font-size: 30px;
  font-weight: 7300;
  color: $gold;
  line-height: 1.2em;
}

lead {
  font-size: 18px;
  line-height: 1.8em;
}

blockquote p {
  color: $dark-green;
  font-size: 18px !important;
  line-height: 1.8em !important;
}

blockquote.border-start {
  border-left: $dark-green 4px solid !important;
}


.small-header {
  text-transform: uppercase;
  font-size: 0.88rem;
  font-weight: 300;
  letter-spacing: .04rem;
  border-top: $gold 1px dotted;
  border-bottom: $gold 1px dotted;
  padding: .5rem 0;
}

.testimonial, .testimonial-name {
  font-style: italic;
}

.testimonial {
  font-weight: 300;
  padding: 1rem 2rem .5rem 0;
  margin-bottom: 0;
}

.testimonial-name {
  margin-top: 0;
  font-weight: 600;
  color: $gold;
}

.services-block {
  text-align: center;
  border-top: none;
  border-bottom: $gold 1px solid;
  padding: 1rem 0 4rem 0;
}

.services-block h2 {
  font-size: 1.8rem;
  font-weight: 600;
  padding: 1rem 0;
}


.blog-title {
  font-weight: 600;
}

.blog-post h2, .blog-post h3 {
  font-weight: 600;
  padding: 1rem 0;
}

.blog-post p {
  font-weight: 400;
}

.blog-post li {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.8em;
}

.blog-post p a {
  text-decoration: underline !important;
}

.blog-title a {
  font-weight: 500;
  text-decoration: none;
  font-size: 1.2rem;
  line-height: 1.7rem !important;
}

.blog-lead {
  padding: 1rem;
  background: $beige;
}

.blog-date {
  color: $gold;
  display: block;
  font-size: 0.7rem;
  margin-top: -4px;
}

.blog-excerpt {
  font-size: 0.8rem;
  line-height: 1.2rem;
  font-weight: 300;
  padding: 0 4rem 0 0;
}

.blog-excerpt a {
  color: $gold;
  font-weight: 300 !important;
}

.sidebar-title {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1rem 0;
}

.blog-content a {
  text-decoration: none;
  font-weight: 500;
}

.blog-content a:hover {
  text-decoration: underline;
}

.sticky-column {
  position: sticky;
  top: 0;
}

.blog-panel {
  padding: 0 2rem;
}

.pagination {
  padding-top: 0;
}

.contact-details li {
  list-style-type: none;
  display: inline-block;
  margin-right: 1px !important;
}

.contact-details li:first-child {
  margin-left: -2.3rem !important;;
}

.contact-details a {
  text-decoration: none;
}

.contact-image {
  background-color: $contact-bg;
  margin-right: 40%;
  border-top: $gold 4px solid;
}


.social-icon {
  height: 24px;
  margin: 0 0.5rem;
}


.form_image {
  width: 260px;
  padding: 1rem 0;
  margin: 0 0 2rem 0;
}

.helptext {
  font-size: 0.8rem;
  font-weight: 300;
  color: $highlight;
}

//////////////////////
//NAVIGATION
//////////////////////

.navbar {
  background: $body-bg;
  //box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);
  border-bottom: $gold 1px solid;
}

.navbar-nav {
  position: relative;
  right: -36px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.navbar-nav .nav-item {
  margin: 0 .4rem;
}

.navbar-logo {
  position: relative;
  left: 10px;
  height: 56px;
  transition: height 0.3s ease;
}

.navbar-logo-shrink {
  height: 46px !important;
  transition: height 0.3s ease;
}

a.nav-link, a.dropdown-item {
  color: $dark-green;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 300;
}

a.nav-link:hover, a.dropdown-item:hover {
  color: $highlight;
  text-decoration: none;
}

.dropdown-menu {
  position: fixed;
  width: 360px;
  background: $body-bg;
  border-radius: 0;
  border: none;
  box-shadow: none;
  margin-top: -10px;
}

.dropdown-menu.show {
  position: relative;
  top: 0;
  left: 20px;
  z-index: 1000;
  box-shadow: none;
}

.dropdown-menu li {
  margin: 8px 0; /* Adjust the value as needed */
}


.navbar-collapse.show .nav-item:first-child {
  margin-top: 30px;
  transition: margin-top 0.1s ease;
}

.navbar-collapse {
  transition: height 0.1s ease;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler-icon {
  position: relative;
  width: 36px;
  right: 10px;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}


//////////////////////
//BUTTONS
//////////////////////

.btn-primary {
  color: $my-primary-color !important;
  letter-spacing: 0.08rem;
  font-weight: 300;
  padding: 0.4rem 1.3rem;
  border-radius: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.25);
}

.btn-primary:hover, .btn-primary:active {
  background-color: $my-primary-hover !important;
  border-color: $my-primary-hover !important;
}


//////////////////////
//FOOTER
//////////////////////

.fixed-footer {
  position: relative;
  left: 0;
  bottom: 0;
  //width: 100%;
}

.light {
  background: $light-beige;
}

.full {
  width: 100%;
  height: 100%;
}

.footer-main-top {
  height: 156px;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position-y: bottom !important;
}

.footer-main {
  background: $gold;
  height: 540px;
  color: $body-bg;
}


.footer-block:first-child {
  padding-top: 2rem;
}

.footer-block {
  text-align: center;
}

.footer-block:not(:first-child) {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 50%; // Center the element
    transform: translateX(-50%); // Center the element
    top: 0; // Position where you want the border to appear
    width: 50%; // Adjust to desired border length
    border-top: 1px solid $dark-green; // Your border style
  }
}

.footer-logo {
  width: 200px;
}

.footer-main h3 {
  font-size: 1.4rem;
  color: $dark-green;
  padding: 1rem 0 .2rem 0;
}

.footer-main p, {
  font-weight: 300;
  margin: 0;
}

.footer-main a {
  color: $body-bg;
  text-decoration: none;
}

.footer-main a:hover {
  text-decoration: underline;
}

.footer-bottom {
  color: $gold;
  font-weight: 300;
  font-size: 0.80rem;
  background: $almost-black;
  padding: 1rem 0;
}

.footer-bottom a {
  color: $gold;
  font-weight: 600;
  text-decoration: none;
}

.footer-bottom a:hover {
  color: $dark-highlight;
  transition: all 0.4s ease-in-out;
}

//////////////////////
//MEDIA QUERIES
//////////////////////

@media (max-width: 766px) { /* Adjust the value as needed */
  .dropdown-menu {
    display: block !important;
  }
  .navbar-nav {
    margin-bottom: 40px;
  }

}

@include media-breakpoint-up(md) {

  .start {
    margin-top: 112px;
    transition: margin-top 0.3s ease;
  }
  .navbar-logo {
    height: 86px;
    left: 20px;
  }

  .hero-text h1 {
    font-size: 2.8rem;
    letter-spacing: 0.2rem;
  }

  .navbar-toggler-icon {
    right: 36px;
  }

  .left-col, .right-col {
    padding: 0 4rem;
  }

  .navbar-nav {
    right: 36px;
  }

  .navbar-collapse.show .nav-item:first-child {
    margin-top: 0;
    transition: margin-top 0.1s ease;
  }

  .dropdown-menu {
    border-left: $gold 1px solid;
    border-right: $gold 1px solid;
    border-bottom: $gold 1px solid;
  }

  .dropdown-menu:focus {
    outline: none;
  }


  .dropdown-menu.show {
    top: 74.5px;
    left: -100px;
    transition: top 0.3s ease;
  }

  .dropdown-menu-shrink {
    top: 54.55px !important;
    transition: top 0.3s ease;
  }

  .sticky-column-shrink {
    top: 120px !important;
    transition: top 0.3s ease;
  }

  .blog-panel {
    padding: 0 4rem 0 0;
  }

}

@include media-breakpoint-up(lg) {

  .left-col {
    padding: 0 1rem 0 2rem;
  }

  .right-col {
    padding: 0 2rem 0 1rem;
  }

  .secondary_image {
    width: 350px;
    padding: 2rem 0;
  }

  .page-header-image {
    height: 100%;
  }

  .text-image-container {
    max-width: 400px;
  }

  .services-block {
    border-top: $gold 1px solid;
  }

  .footer-main {
    height: 220px;

    .footer-block {
      padding-top: 1rem;
      text-align: left;
    }

    .footer-block:not(:first-child)::after {
      border: none;
    }
  }

  .title-block {
    width: 60%
  }

  .pagination {
    padding-top: 2rem;
  }

}
