// Place variable overrides first, then import just the styles you need.
// Note that some stylesheets are required no matter what.


// Custom colours
$gold: #9e7f56;
$active: #9e7f56;
$almost-black: #1d1d1d;
$highlight: #5a7e70;
$dark-highlight: #6f6046;
$dark-green: #134632;
$pink: #ff69b4;
$my-primary: $gold;
$my-primary-hover: #134632;
$my-primary-color: #ffffff;
$my-secondary: #134632;
$beige: #efeae4;
$light-beige: #f1ede2;
$contact-bg: #11110f;

// Customise defaults
$body-color: #333;
$body-bg: #fff;
$primary: $my-primary;
$secondary: $my-secondary;
$enable-gradients: true;
$enable-shadows: true;


@import "~bootstrap/scss/bootstrap";

// Add custom styles
@import "_global";

